const RACES: Race[] = [
  {
    number: 1,
    date: new Date(Date.UTC(2022, 2, 22, 15, 0, 0)),
    amountSold: 0,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 2,
    date: new Date(Date.UTC(2022, 3, 1, 15, 0, 0)),
    amountSold: 100,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 3,
    date: new Date(Date.UTC(2022, 3, 11, 16, 0, 0)),
    amountSold: 50,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 4,
    date: new Date(Date.UTC(2022, 3, 23, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 5,
    date: new Date(Date.UTC(2022, 4, 7, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 6,
    date: new Date(Date.UTC(2022, 4, 21, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 7,
    date: new Date(Date.UTC(2022, 4, 28, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 8,
    date: new Date(Date.UTC(2022, 5, 11, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 9,
    date: new Date(Date.UTC(2022, 5, 18, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 10,
    date: new Date(Date.UTC(2022, 6, 2, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 11,
    date: new Date(Date.UTC(2022, 6, 9, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 12,
    date: new Date(Date.UTC(2022, 6, 23, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 13,
    date: new Date(Date.UTC(2022, 7, 1, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 14,
    date: new Date(Date.UTC(2022, 7, 29, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 15,
    date: new Date(Date.UTC(2022, 8, 5, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 16,
    date: new Date(Date.UTC(2022, 8, 12, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 17,
    date: new Date(Date.UTC(2022, 9, 3, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 18,
    date: new Date(Date.UTC(2022, 9, 10, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 19,
    date: new Date(Date.UTC(2022, 9, 24, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 20,
    date: new Date(Date.UTC(2022, 9, 31, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 21,
    date: new Date(Date.UTC(2022, 10, 14, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
  {
    number: 22,
    date: new Date(Date.UTC(2022, 10, 21, 19, 0, 0)),
    amountSold: 25,
    soldOut: false,
    saleLive: false,
  },
];

export type Race = {
  number: number;
  date: Date;
  amountSold: number;
  soldOut: boolean;
  saleLive: boolean;
};

export const BASE_LINE_SOLD = 293;

const useRaces = () => {
  return RACES;
};

export const updateRacesDatesWithSoldAmount = (
  races: Race[],
  soldAmount: number
): Race[] => {
  let currentAmount = BASE_LINE_SOLD;
  return races.map((race) => {
    currentAmount += race.amountSold;
    return {
      ...race,
      soldOut: soldAmount >= currentAmount,
      saleLive: race.date.getTime() < new Date().getTime(),
    };
  });
};

export default useRaces;
