import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useRouter } from "next/router";
import devContract from "../assets/abi_dev.json";
import prodContract from "../assets/abi_prod.json";
import addresses from "../assets/whitelist.json";
import airdropAddresses from "../assets/airdrop_whitelist.json";
import { captureException } from "@sentry/nextjs";
import { useErrorReporting } from "../context/ErrorContext";
import {
  ERROR_AIRDROP_ALREADY_CLAIMED,
  ERROR_INSUFFICIENT_FUNDS,
  ERROR_INVALID_PROOF,
  ERROR_NETWORK_VERSION,
  ERROR_UNKNOWN_ERROR,
  ERROR_WALLET_CONNECTION,
  ERROR_WALLET_NOT_CONNECTED,
} from "../utils/errors";
import { BigNumber, ethers } from "ethers";
import keccak256 from "keccak256";
import MerkleTree from "merkletreejs";

declare var window: any;

const contractAddress = process.env.NEXT_PUBLIC_CONTRACT_ADDRESS;
const abi =
  process.env.NODE_ENV === "development" ? devContract.abi : prodContract.abi;

export const useWeb3 = (resetForm) => {
  const router = useRouter();
  const { reportError } = useErrorReporting();
  const [currentAccount, setCurrentAccount] = useState<string | undefined>(
    undefined
  );

  const resetLogin = () => {
    setCurrentAccount(undefined);
    resetForm();
  };

  const checkWalletIsConnected = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        return;
      }

      ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length !== 0) {
            const account = accounts[0];
            setCurrentAccount(account);
          } else {
            resetLogin();
          }
        })
        .catch((err) => {
          captureException(err);
          reportError(ERROR_UNKNOWN_ERROR);
          throw new Error();
        });
    } catch (err) {
      captureException(err);
      reportError(ERROR_WALLET_CONNECTION);
      throw new Error();
    }
  };

  const connectWallet = async () => {
    const { ethereum } = window;

    if (!ethereum) {
      if (isMobile) {
        router.push("https://metamask.app.link/dapp/www.mrvroomnft.com/mint");
        return;
      }
      reportError(ERROR_WALLET_NOT_CONNECTED);
      throw new Error();
    }

    if (
      parseInt(ethereum.networkVersion) !==
      (process.env.NODE_ENV === "development" ? 4 : 1)
    ) {
      reportError(ERROR_NETWORK_VERSION);
      return;
    }

    try {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setCurrentAccount(accounts[0]);
    } catch (err) {
      captureException(err);
      reportError(ERROR_UNKNOWN_ERROR);
      throw new Error();
    }
  };

  const getWhitelistMerkleProof = async (address) => {
    const keccak256 = await import("keccak256").then((lib) => lib);
    const whitelistLeafNodes = addresses.map((claimer) =>
      keccak256.default(claimer)
    );

    import("merkletreejs").then((merkleTree) => {
      const whitelistMerkleTree = new merkleTree.MerkleTree(
        whitelistLeafNodes,
        keccak256,
        {
          sortPairs: true,
        }
      );

      const root = whitelistMerkleTree.getRoot();

      const leaf = keccak256.default(address);
      const proof = whitelistMerkleTree.getProof(leaf);

      whitelistMerkleTree.verify(proof, leaf, root);

      const merkleProof = proof.map((proof) => proof["data"]);
      if (merkleProof.length < 1) {
        reportError(ERROR_INVALID_PROOF);
        return;
      }
      return merkleProof;
    });
  };

  const getAirdropMerkleProof = async (address) => {
    const whitelistLeafNodes = airdropAddresses.map((claimer) =>
      keccak256(claimer)
    );

    const whitelistMerkleTree = new MerkleTree(whitelistLeafNodes, keccak256, {
      sortPairs: true,
    });
    const root = whitelistMerkleTree.getRoot();

    const leaf = keccak256(address);
    const proof = whitelistMerkleTree.getProof(leaf);

    whitelistMerkleTree.verify(proof, leaf, root);

    const merkleProof = proof.map((proof) => proof["data"]);
    if (merkleProof.length < 1) {
      reportError(ERROR_INVALID_PROOF);
      return;
    }
    return merkleProof;
  };

  const getTransactionPrice = (
    isWhitelist: boolean,
    amount: number
  ): string => {
    if (isWhitelist) {
      if (amount == 4) {
        return "0.18";
      } else if (amount == 20) {
        return "0.8";
      } else {
        return (amount * 0.05).toString();
      }
    } else {
      return (amount * 0.05).toString();
    }
  };

  const whitelistMint = async (
    amount: number,
    type: number,
    address: string,
    setHash: React.Dispatch<React.SetStateAction<string>>
  ): Promise<number> => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        return import("ethers").then(async (ethers) => {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);

          const merkleProof = await getWhitelistMerkleProof(address);

          let price: ethers.BigNumber;

          if (type == 0) {
            price = ethers.utils.parseEther((0.05 * amount).toString());
          } else if (type == 1) {
            price = ethers.utils.parseEther("0.18");
          } else if (type == 2) {
            price = ethers.utils.parseEther("0.8");
          }

          let nftTxn = await nftContract.whitelistMint(
            amount,
            type,
            merkleProof,
            {
              value: price,
            }
          );

          setHash(nftTxn.hash);

          const event = await nftTxn.wait();
          const events = event.events;
          const lastId = events[events.length - 1]["args"]
            .slice(-1)[0]
            .toNumber();

          return lastId;
        });
      } else {
        reportError(ERROR_WALLET_NOT_CONNECTED);
      }
    } catch (err) {
      captureException(err);
      if (err.error.message.includes("insufficient funds")) {
        reportError(ERROR_INSUFFICIENT_FUNDS);
      } else {
        reportError(ERROR_UNKNOWN_ERROR);
      }
      throw new Error();
    }
  };

  const publicMint = async (
    amount,
    setHash: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        return import("ethers").then(async (ethers) => {
          const provider = new ethers.providers.Web3Provider(ethereum);
          const signer = provider.getSigner();
          const nftContract = new ethers.Contract(contractAddress, abi, signer);

          const price = ethers.utils.parseEther(
            getTransactionPrice(false, amount)
          );

          let nftTxn = await nftContract.mint(amount.toString(), {
            value: price,
          });

          setHash(nftTxn.hash);

          const event = await nftTxn.wait();
          const events = event.events;
          const lastId = events[events.length - 1]["args"]
            .slice(-1)[0]
            .toNumber();

          return lastId;
        });
      } else {
        reportError(ERROR_WALLET_NOT_CONNECTED);
      }
    } catch (err) {
      captureException(err);
      if (err.error.message.includes("insufficient funds")) {
        reportError(ERROR_INSUFFICIENT_FUNDS);
      } else {
        reportError(ERROR_UNKNOWN_ERROR);
      }
      throw new Error();
    }
  };

  const airdrop = async (
    address,
    setHash: React.Dispatch<React.SetStateAction<string>>
  ) => {
    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const nftContract = new ethers.Contract(contractAddress, abi, signer);

        let merkleProof;

        merkleProof = await getAirdropMerkleProof(address);

        let nftTxn = await nftContract.airdrop(merkleProof);

        setHash(nftTxn.hash);

        const event = await nftTxn.wait();
        const events = event.events;
        const lastId = events[events.length - 1]["args"]
          .slice(-1)[0]
          .toNumber();
        return lastId;
      } else {
        reportError(ERROR_WALLET_NOT_CONNECTED);
      }
    } catch (error) {
      console.log(error);

      captureException(error);
      if (error.message.includes("proof")) {
        reportError(ERROR_INVALID_PROOF);
      } else if (error.message.includes("already claimed")) {
        reportError(ERROR_AIRDROP_ALREADY_CLAIMED);
      } else {
        reportError(ERROR_UNKNOWN_ERROR);
      }
      throw new Error();
    }
  };

  const onAccountChanged = (accounts) => {
    const { ethereum } = window;

    if (!ethereum) {
      return;
    }
    if (
      parseInt(ethereum.networkVersion) !==
      (process.env.NODE_ENV === "development" ? 4 : 1)
    ) {
      resetLogin();
      reportError(ERROR_NETWORK_VERSION);
      return;
    }
    if (accounts.length !== 0) {
      const account = accounts[0];
      setCurrentAccount(account);
    } else {
      resetLogin();
      resetForm();
    }
  };

  const initAccountListener = () => {
    const { ethereum } = window;

    if (!ethereum) {
      return;
    }
    ethereum.on("accountsChanged", onAccountChanged);
  };

  const chainChanged = (chain) => {
    if (parseInt(chain) !== (process.env.NODE_ENV === "development" ? 4 : 1)) {
      resetLogin();
      reportError(ERROR_NETWORK_VERSION);
      return;
    }
  };

  const initChainChangedListener = () => {
    const { ethereum } = window;

    if (!ethereum) {
      return;
    }
    ethereum.on("chainChanged", chainChanged);
  };

  const removeEventListeners = () => {
    const { ethereum } = window;

    if (!ethereum) {
      return;
    }
    ethereum.removeListener("chainChanged", chainChanged);
    ethereum.removeListener("accountsChanged", onAccountChanged);
  };

  const getTotalSold = (): Promise<BigNumber> => {
    try {
      return import("ethers").then(async (ethers) => {
        const provider = new ethers.providers.InfuraProvider(
          process.env.NODE_ENV === "development" ? "rinkeby" : "homestead",
          "99fbf837bd974228beb8912ade0aa7f9"
        );
        const nftContract = new ethers.Contract(contractAddress, abi, provider);
        return nftContract.totalSupply();
      });
    } catch (err) {
      captureException(err);
      throw new Error();
    }
  };

  useEffect(() => {
    checkWalletIsConnected();
  }, []);

  return {
    checkWalletIsConnected,
    connectWallet,
    currentAccount,
    airdrop,
    publicMint,
    whitelistMint,
    initAccountListener,
    initChainChangedListener,
    removeEventListeners,
    getTotalSold,
  };
};
