import classNames from "classnames";
import Image from "next/image";
import unconnectedLogo from "../../public/meta_mask_logo.png";
import connectedLogo from "../../public/meta_mask_logo_connected.png";

export type ConnectButtonProps = {
  currentAccount?: any;
  connectWallet: () => Promise<void>;
};

const ConnectButton: React.FunctionComponent<ConnectButtonProps> = ({
  currentAccount,
  connectWallet,
}) => (
  <div
    onClick={currentAccount ? undefined : connectWallet}
    className={classNames(
      "cursor-pointer px-4 py-2 flex items-center justify-center space-x-3 text-white text-semibold rounded-sm ",
      currentAccount
        ? "bg-dark-green border-green border w-40"
        : "bg-black border-border border"
    )}
  >
    <Image
      alt="wallet"
      src={currentAccount ? connectedLogo : unconnectedLogo}
      width={20}
      height={20}
    />
    <p>
      {currentAccount
        ? `${currentAccount.substring(0, 5)}...${currentAccount.substring(
            currentAccount.length - 5,
            currentAccount.length
          )}`
        : "Connect to MetaMask"}
    </p>
  </div>
);

export default ConnectButton;
