import React from "react";
import { Race } from "../../data/useRaces";

type SoldOutBoxProps = {
  race: Race;
};

const SoldOutBox: React.FunctionComponent<SoldOutBoxProps> = ({ race }) => {
  return (
    <div className="flex flex-col items-center justify-center pt-5 rounded-base w-11/12 md:w-[440px] bg-blue rounded-base">
      <div className="border-b border-border pb-5 w-full flex items-center justify-center">
        <p className="font-semibold">Race {race.number - 1}/23 Has Ended</p>
      </div>
      <div className="w-full pt-20 flex flex-col items-center">
        <h1 className="font-formula text-smhead pb-5">
          Race {race.number - 1} - Sold out
        </h1>
        <p className="text-xs text-white text-opacity-60 text-center px-10 pb-10">
          mrVroom mints in limited amounts after each Formula 1 race weekend.
          Each weekend, mrVroom will mint between 50-200 NFTs, and 20 airdrops.
          Airdrops are won by claiming bounties or collaborations.
        </p>
        <div className="px-6 pb-6 w-full">
          <div className="w-full flex items-center justify-center text-xs text-white text-opacity-60 py-4 rounded border-border border">
            Next Race: {race.date.toDateString()} 12:00pm PST
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoldOutBox;
