import React from "react";
import Image from "next/image";
import placeholder from "../../public/placeholder.jpeg";
import ShareIcon from "../../assets/share_icon.svg";

export type PreviewBoxProps = {
  tokenIds: number[];
};

const PreviewBox: React.FunctionComponent<PreviewBoxProps> = ({ tokenIds }) => {
  return (
    <div className="grid gap-12 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
      {tokenIds.map((id) => (
        <div key={id} className="flex flex-col space-y-2 font-semibold">
          <div>
            <Image src={placeholder} alt="" width={300} height={300} />
          </div>
          <div className="flex justify-between items-center">
            <p>mrVroom Single NFT</p>
            <a
              target="_blank"
              rel="noreferrer"
              href={`https://opensea.io/assets/0x267cfee6671c776cbdf10aadcb90dcd9db242723/${
                id - 1
              }`}
            >
              <ShareIcon />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PreviewBox;
