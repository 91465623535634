import React from "react";
import Spinner from "../Spinner/Spinner";
import ShareIcon from "../../assets/share_icon.svg";

export type LoadingBoxProps = {
  transactionHash: string;
};

const LoadingBox: React.FunctionComponent<LoadingBoxProps> = ({
  transactionHash,
}) => {
  return (
    <div className="flex flex-col items-center justify-center pt-5 rounded-base w-11/12 md:w-[440px] bg-blue rounded-base">
      <div className="border-b border-border pb-5 w-full flex items-center justify-center ">
        <p className="font-semibold">Light off and away we go</p>
      </div>
      <div className="py-32 text-medium font-semibold flex flex-col space-y-6 flex flex-col items-center">
        <Spinner />
        <p>Minting NFT...</p>
      </div>
      <div className="pb-8">
        {transactionHash && (
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://etherscan.io/tx/${transactionHash}`}
            className="flex items-center justify-center space-x-2 text-light-red"
          >
            <p className="underline cursor-pointer font-semibold text-base">
              View on Etherscan
            </p>
            <ShareIcon className="fill-current " />
          </a>
        )}
      </div>
    </div>
  );
};

export default LoadingBox;
