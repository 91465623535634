export type CustomError = {
  title: string;
  message: string;
  code: string;
};

export const ERROR_INSUFFICIENT_FUNDS: CustomError = {
  message:
    "Unfortunately you have insufficient funds. Please upload funds to buy a vroom",
  title: "Insufficient Funds",
  code: "Verstappen",
};

export const ERROR_INVALID_PROOF: CustomError = {
  message:
    "Unfortunately you are not whitelisted for paddock or airdrop mint. For more help, please open a ticket on Discord",
  title: "Not whitelisted",
  code: "Hamilton",
};

export const ERROR_PUBLIC_SUPPLY_EXCEEDED: CustomError = {
  message: "All publicly available Drivers were already minted",
  title: "Max public supply exceeded!",
  code: "Vettel",
};

export const ERROR_AIRDROP_SUPPLY_EXCEEDED: CustomError = {
  message: "All airdropable Drivers were already minted",
  title: "Max airdrop supply exceeded!",
  code: "Leclerc",
};

export const ERROR_INVALID_AMOUNT: CustomError = {
  message:
    "The amount of you sent is not correct. Please try again with the correct amount!",
  title: "Amount sent incorrect",
  code: "Schumacher",
};

// export const ERROR_WHITELIST_INACTIVE: CustomError = {
//   message:
//     "Please wait until the next race has started to claim your whitelist mint.",
//   title: "Whitelist not active",
//   code: "Russell",
// };

// export const ERROR_SALE_INACTIVE: CustomError = {
//   message: "Please wait until the next race has started to claim your driver.",
//   title: "Sale not active",
//   code: "Bottas",
// };

// export const ERROR_ALL_BOXES_SOLD: CustomError = {
//   message: "Unfortunately all boxes already have been sold!",
//   title: "All boxes sold out",
//   code: "Riccardo",
// };

// export const ERROR_ALL_PACKS_SOLD: CustomError = {
//   message: "Unfortunately all packs already have been sold!",
//   title: "All packs sold out",
//   code: "Gasly",
// };

export const ERROR_AIRDROP_ALREADY_CLAIMED: CustomError = {
  message: "You already claimed your airdrop for this race.",
  title: "Airdrop already claimed",
  code: "Perez",
};

export const ERROR_UNKNOWN_ERROR: CustomError = {
  title: "Red flag",
  message:
    "Red flag on the grid - you attempted to pass outside of track limits. Please follow track limits, and in this case, wait one day for public mint. For giveaways, and early info, please head over to our Discord.",
  code: "Perez",
};

export const ERROR_WALLET_CONNECTION: CustomError = {
  message: "There seems to be a problem when connecting to your wallet.",
  title: "Wallet connection error",
  code: "Perez",
};

export const ERROR_WALLET_NOT_CONNECTED: CustomError = {
  message: "There is no wallet installed in your browser.",
  title: "No wallet installed",
  code: "Perez",
};

export const ERROR_NETWORK_VERSION: CustomError = {
  message:
    "Your wallet seems to be connected to the wrong network. Please make sure you are connected to the Ethereum mainnet.",
  title: "Wrong network version",
  code: "Perez",
};
