import classNames from "classnames";
import React, { useState } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import PlusIcon from "../../assets/ic_plus.svg";
import MinusIcon from "../../assets/ic_minus.svg";
import Spinner from "../Spinner/Spinner";

export type MintingOption = {
  title: string;
  optionType: MintingOptionTypes;
  subtitle?: string;
  price: number;
  soldOut: boolean;
  active: boolean;
  discount: number;
  amount: number;
};

export type MintingOptionTypes = "single" | "pack" | "box";

export type MintFormFields = {
  option: MintingOptionTypes;
  amount: number;
};

export type MintingBoxProps = {
  form: UseFormReturn<MintFormFields>;
  onMint: () => Promise<void>;
  mintingOptions: MintingOption[];
  currentAccount: string | undefined;
};

const MintingBox: React.FunctionComponent<MintingBoxProps> = ({
  form: { control, watch },
  onMint,
  mintingOptions,
  currentAccount,
}) => {
  const { option, amount } = watch();

  return (
    <div className="flex flex-col items-center justify-center pt-5 rounded-base w-11/12 md:w-[440px] bg-blue rounded-base">
      <div className="border-b border-border pb-5 w-full flex items-center justify-center">
        <p className="font-semibold">
          {currentAccount
            ? "mrVroom randomly generated NFT"
            : "Connect to Metamask to Mint"}
        </p>
      </div>
      <div className="w-full px-6 pt-6">
        <div className="space-y-4">
          <Controller
            control={control}
            name="amount"
            render={({
              field: { onChange: onChangeAmount, value: amountValue },
            }) => {
              return (
                <Controller
                  control={control}
                  name="option"
                  render={({
                    field: { onChange: onChangeOption, value: optionValue },
                  }) => {
                    return (
                      <>
                        {mintingOptions.map((option) => (
                          <MintingOptionBox
                            key={option.title}
                            {...option}
                            selected={option.optionType === optionValue}
                            onSelect={() => {
                              onChangeAmount(option.amount);
                              onChangeOption(option.optionType);
                            }}
                            onChangeAmount={onChangeAmount}
                            amount={amountValue}
                          />
                        ))}
                      </>
                    );
                  }}
                />
              );
            }}
          />
        </div>
        <div className="pb-6"></div>
        <div
          className={classNames(
            "bg-white bg-opacity-20 rounded-base text-white text-opacity-40 py-4 mb-6 flex cursor-pointer items-center justify-center font-semibold",
            option && amount > 0 && "bg-red bg-opacity-100 text-opacity-100"
          )}
          onClick={onMint}
        >
          Mint Now
        </div>
      </div>
      <div className="bg-dark-blue px-5 py-3 rounded-b-base">
        <p className="text-xs text-white text-opacity-60">
          *We&apos;ve implemented ERC721A, an improved implementation of the
          IERC721 standard by Azuki that allows you to mint multiple NFTs for
          essentially the same gas fees of minting a single NFT.
        </p>
      </div>
    </div>
  );
};

export default MintingBox;

export type MintingOptionProps = {
  title: string;
  subtitle?: string;
  price: number;
  optionType: MintingOptionTypes;
  soldOut: boolean;
  active: boolean;
  selected: boolean;
  onSelect: () => void;
  onChangeAmount: (adder: number) => void;
  amount: number;
  discount: number;
};

const MintingOptionBox: React.FunctionComponent<MintingOptionProps> = ({
  title,
  subtitle,
  price,
  optionType,
  soldOut,
  active,
  selected,
  onSelect,
  onChangeAmount,
  amount,
  discount,
}) => {
  const totalPrice =
    optionType === "single" && selected ? price * amount : price;
  return (
    <div
      className={classNames(
        "flex items-center justify-between rounded-base cursor-pointer bg-white w-full text-white select-none border-2",
        active
          ? "bg-opacity-8 text-opacity-100"
          : "bg-opacity-4 text-opacity-40",
        selected ? "border-light-red border-opacity-100" : "border-transparent"
      )}
      onClick={active && !selected ? onSelect : undefined}
    >
      <div className="flex space-x-1 pl-4 py-4">
        <p className="font-semibold">{title}</p>
        <p>{subtitle}</p>
      </div>
      {selected && optionType === "single" && (
        <div className="flex space-x-4 items-center justify-center">
          <div
            onClick={() => onChangeAmount(amount > 1 ? amount - 1 : amount)}
            className="flex items-center justify-center h-8 w-8 bg-white bg-opacity-20 rounded-full"
          >
            <MinusIcon />
          </div>
          <p>{amount}</p>
          <div
            onClick={() => onChangeAmount(amount + 1)}
            className="flex items-center justify-center h-8 w-8 bg-red rounded-full"
          >
            <PlusIcon />
          </div>
        </div>
      )}
      <div className="flex pr-4 py-4 space-x-4">
        {discount && (
          <div className="flex items-center justify-center bg-white bg-opacity-12 rounded-sm text-white text-xxs px-1.5 py-0.5 text-opacity-80">
            {`Save ${discount}%`}
          </div>
        )}
        <div className="font-semibold">
          {!soldOut ? (
            `${totalPrice.toFixed(2)} ETH`
          ) : (
            <div className="bg-white items-center justify-center bg-opacity-12 rounded-sm text-white text-xxs px-1.5 py-0.5 text-opacity-80">
              Sold Out
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
