import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MintingBox, {
  MintFormFields,
  MintingOption,
  MintingOptionTypes,
} from "../../components/MintingBox/MintingBox";
import { useWeb3 } from "../../data/useWeb3";

import Logo from "../../assets/logo.svg";
import ConnectButton from "../../components/ConnectButton/ConnectButton";
import LoadingBox from "../../components/LoadingBox/LoadingBox";
import PreviewBox from "../../components/PreviewBox/PreviewBox";
import { useRouter } from "next/router";
import SoldOutBox from "../../components/SoldOutBox/SoldOutBox";
import Spinner from "../../components/Spinner/Spinner";
import useRaces, {
  updateRacesDatesWithSoldAmount,
  Race,
} from "../../data/useRaces";

const optionTypeToNumber = (optionType: MintingOptionTypes): number => {
  if (optionType === "single") {
    return 0;
  }
  if (optionType === "pack") {
    return 1;
  }
  if (optionType === "box") {
    return 2;
  }
};

const constructMintingOptions = (
  currentAccount,
  whitelist: boolean
): MintingOption[] => {
  return whitelist
    ? [
        {
          title: "mrVroom NFT",
          optionType: "single",
          subtitle: undefined,
          price: 0.05,
          soldOut: false,
          active: currentAccount,
          discount: undefined,
          amount: 1,
        },
        {
          title: "1 Pack",
          optionType: "pack",
          subtitle: "(4 NFT's)*",
          price: 0.18,
          soldOut: false,
          active: currentAccount,
          discount: 10,
          amount: 4,
        },
        {
          title: "1 Box",
          optionType: "box",
          subtitle: "(20 NFT's)*",
          price: 0.8,
          soldOut: false,
          active: currentAccount,
          discount: 20,
          amount: 20,
        },
      ]
    : [
        {
          title: "mrVroom NFT",
          optionType: "single",
          subtitle: undefined,
          price: 0.05,
          soldOut: false,
          active: currentAccount,
          discount: undefined,
          amount: 1,
        },
      ];
};

export type MintingStep = "choose" | "load" | "preview";

const Mint: React.FunctionComponent = () => {
  const router = useRouter();
  const whitelist = false;
  // RACE_DATES.find(
  //   (race) =>
  //     race.getTime() > new Date().getTime() - 1000 * 60 * 60 * 3 &&
  //     race.getTime() < new Date().getTime()
  // ) !== undefined;

  const [step, setStep] = useState<MintingStep>("choose");
  const [tokenIds, setTokenIds] = useState<number[]>([]);
  const [transactionHash, setTransactionHash] = useState<string>();

  const mintingForm = useForm<MintFormFields>({
    defaultValues: { amount: 1, option: undefined },
  });

  const {
    connectWallet,
    whitelistMint,
    publicMint,
    currentAccount,
    initAccountListener,
    initChainChangedListener,
    getTotalSold,
    removeEventListeners,
  } = useWeb3(() => mintingForm.reset());

  const onMint = () => {
    return mintingForm.handleSubmit((data) => {
      setStep("load");
      if (whitelist) {
        whitelistMint(
          data.amount,
          optionTypeToNumber(data.option),
          currentAccount,
          setTransactionHash
        )
          .then((lastId) => {
            setTokenIds(
              Array(data.amount)
                .fill(0)
                .map((_, idx) => {
                  return lastId - (data.amount - (idx + 1));
                })
            );
            setStep("preview");
          })
          .catch(() => {
            setStep("choose");
          });
      } else {
        publicMint(data.amount, setTransactionHash)
          .then((lastId) => {
            setTokenIds(
              Array(data.amount)
                .fill(0)
                .map((_, idx) => {
                  return lastId - (data.amount - (idx + 1));
                })
            );
            setStep("preview");
          })
          .catch(() => {
            setStep("choose");
          });
      }
    })();
  };

  useEffect(() => {
    initChainChangedListener();
    initAccountListener();
    return () => {
      removeEventListeners();
    };
  }, [initAccountListener, initChainChangedListener]);

  const [isInitializing, setIsInitializing] = useState(true);

  const mintingOptions = constructMintingOptions(currentAccount, whitelist);

  const [races, setRaces] = useState<Race[]>(useRaces());

  useEffect(() => {
    getTotalSold()?.then((value) => {
      setIsInitializing(false);
      setRaces(updateRacesDatesWithSoldAmount(races, value.toNumber()));
    });
  }, []);

  const activeRace = races.find((race) => race.saleLive && !race.soldOut);
  const upcomingRaces = races.filter((race) => !race.saleLive);
  const nextRace = upcomingRaces.reduce((min, currentRace) =>
    min.number < currentRace.number ? min : currentRace
  );

  return (
    <div className="min-w-screen min-h-screen w-full h-full text-base text-white py-6 bg-gray-background px-4 md:px-0">
      <div className="pb-10 md:pb-0 md:absolute right-10 top-6">
        <ConnectButton
          connectWallet={connectWallet}
          currentAccount={currentAccount}
        />
      </div>
      <div className="flex flex-col items-center">
        <div onClick={() => router.push("/")} className="cursor-pointer">
          <Logo />
        </div>
        <p className="text-head pt-12 font-formula font-semibold">mrVroom</p>
        <p className="text-center text-white text-opacity-40 text-medium pt-7">
          Claim your 2022 mrVroom NFT. Claim a piece of history.
        </p>
        <div className="pt-8 flex flex-col items-center w-full pb-16">
          {isInitializing && (
            <div className="flex flex-col py-28">
              <Spinner />
            </div>
          )}
          {step === "choose" &&
            !isInitializing &&
            (activeRace ? (
              <MintingBox
                form={mintingForm}
                onMint={currentAccount ? onMint : undefined}
                mintingOptions={mintingOptions}
                currentAccount={currentAccount}
              />
            ) : (
              <SoldOutBox race={nextRace} />
            ))}
          {step === "load" && <LoadingBox transactionHash={transactionHash} />}
          {step === "preview" && <PreviewBox tokenIds={tokenIds} />}
        </div>
      </div>
    </div>
  );
};

export default Mint;
