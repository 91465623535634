import React from "react";

const Spinner = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="border-red border-t-transparent animate-spin inline-block w-8 h-8 border-4 rounded-full"></div>
    </div>
  );
};

export default Spinner;
